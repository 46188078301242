/**Packages */
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";

/**Helpers */
import {
    SITE_TITLE,
    MENU_DATE_FORMAT,
    DEFAULT_OCCASION,
    DEFAULT_DIET,
    DEFAULT_YEAR,
    INDEX_URL,
    PAGES,
} from "../../helpers/constants";
import {
    getCurrentLanguage,
    getYearByDietName,
    getDietNameFormatted,
    convertDietProfileIntoDietMenu,
    getNewsHome,
    getActivities,
    getActivitiesAreaA5,
    getAdvices,
    getNewsChildren,
    sortByPosAscending,
    sortByPosDescending,
    checkIfStringIsHtml,
    openUrl,
    getIfUserHasEnabledToRate,
    mediaButtonOnClick,
} from "../../helpers/utils";
import { getTranslatedString } from "../../helpers/translation";
import { seo } from "../../helpers/seo";
import { success, error } from "../../helpers/toast";
import { scrollTop } from "../../helpers/scroll";
import { checkIfChildrenModeIsActivaded } from "../../helpers/view";
import {
    getIfMenuAsteriskIsEnabled,
    getDownloadKeyString,
    getIfImageRecommendationsEnabled,
    getIfRecipeDetailEnabled,
    getIfNewsDirectLinkEnabled,
    getIfParentRatingRecipesIsEnabled,
    getIfChildrenRatingRecipesIsEnabled,
    getIfNewOccasionSelectionIsEnabled,
    getIfDownloadPdfWithAllOccasionsIsEnabled,
    checkIfRateRecipeIsEnabled,
    getIfSoHappy,
} from "../../helpers/enviroment";
import {
    isValidMenu,
    getBaseMenuObjFormatted,
    getFormattedMenu,
    getCorrectObjFromYear,
    getNutrinionalData,
    getNutritionalDataHtml,
    checkDietExistenceInNewYear,
    getRecipesFromOccasionAndDietId,
    getRecipesFromOccasionAndDietIdForRates,
    getValidAndWithRecipesOccasions,
    getFullMenuDataFormatted,
    getDataForDownloadPdf,
    setOccasionToBody,
    setDietToBody,
    getIfNutritionalDataAreEnabled,
} from "../../helpers/menu";
import { changeCalendarDate, seeRecipeDetailData, downloadMenuData } from "../../helpers/dataLayer";
import history from "../../helpers/history";
import Result from "../../helpers/out";

/**Custom Components */
import MenuComponent from "../../components/Menu";
import DownloadButton from "../../components/DownloadButton";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import DateSlider from "../../components/DateSlider";
import InfanitalCicloSlider from "../../components/InfanitalCicloSlider";
import MenuGeneralSlider from "../../components/MenuGeneralSlider";
import ActivitySlider from "../../components/ActivitySlider";
import GamesSlider from "../../components/GamesSlider";
import NewsSlider from "../../components/NewsSlider";
import Spacer from "../../components/Spacer";
import CustomSelect from "../../components/CustomSelect";
import Margin from "../../components/Margin";
import { RateRecipeButton } from "../../components/RateRecipeButton";
import { RateRecipesModal } from "../../components/RateRecipesModal";

/**Firebase Context */
import { FirebaseContext } from "../../firebase/firebase";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../redux/actions/loaderActions";
import { SET_PAGE_STATE, DELETE_PAGE_STATE } from "../../redux/actions/pageStateActions";
import { SET_NEWS } from "../../redux/actions/newsActions";
import { MENU_RECEIVED } from "../../redux/actions/menuActions";

/**API */
import { api } from "../../api/index";

/**PDF UTIL */
import { downloadPdf } from "./../../helpers/pdf";

/**Images */
import bustaSvg from "../../images/busta.svg";
import recommendationsSvg from "../../images/recommendations.svg";

/**SCSS */
import "./_menu.scss";

/**
 * Used to generate the tab selectors;
 */
const getFormattedOccasion = (occasion, idx, onOccasionSelected, selectedOccasion) => {
    if (occasion) {
        const hrefOccasionTab = getTranslatedString(occasion.occasion_name, getCurrentLanguage()).toLowerCase(),
            id = hrefOccasionTab + "-tab",
            href = "#" + hrefOccasionTab,
            aria_controls = hrefOccasionTab,
            selected =
                selectedOccasion === DEFAULT_OCCASION
                    ? idx === 0
                    : occasion?.occasion_id === selectedOccasion?.occasion_id;

        return (
            <li key={id} className="nav-item" role="presentation">
                <a
                    className={selected ? "nav-link active" : "nav-link"}
                    id={id}
                    data-toggle="tab"
                    href={href}
                    role="tab"
                    aria-controls={aria_controls}
                    aria-selected={selected}
                    onClick={e => onOccasionSelected(e, occasion)}
                >
                    {occasion.occasion_name[getCurrentLanguage()]}
                </a>
            </li>
        );
    }

    return null;
};
/**
 * Used to generate the occasion selectors -> new method select;
 */
const getFormattedOccasionWithNewElement = (occasions, idx, onOccasionSelected, selectedOccasion) => {
    return (
        <CustomSelect
            items={occasions}
            selectedItem={selectedOccasion}
            defaultItem={DEFAULT_OCCASION}
            onChange={onOccasionSelected}
        />
    );
};
/**
 * Used to generate the tabs related to selectors;
 */
const getFormattedOccasionTabs = (occasion, idx, fullRecipes, selectedOccasion, selectedDiet, onRecipeClick) => {
    if (occasion) {
        const hrefOccasionTab = getTranslatedString(occasion.occasion_name, getCurrentLanguage()).toLowerCase(),
            aria_labelledby = hrefOccasionTab + "-tab",
            id = hrefOccasionTab,
            className =
                (selectedOccasion === DEFAULT_OCCASION && idx === 0) ||
                selectedOccasion?.occasion_id === occasion?.occasion_id
                    ? "tab-pane fade show active"
                    : "tab-pane fade",
            recipes = getRecipesFromOccasionAndDietId(fullRecipes, occasion.occasion_id, selectedDiet.diet_id);

        return (
            <div className={className} id={id} key={id} role="tabpanel" aria-labelledby={aria_labelledby}>
                <MenuComponent recipes={recipes} onRecipeClick={onRecipeClick} />
            </div>
        );
    }

    return null;
};
/**
 * SELECTOR TABS HTML
 */
const getOccasionsTabsSelectorHtml = (selectedMenu, occasions, selectedOccasion, onOccasionSelected, t) => {
    return isValidMenu(selectedMenu) ? (
        getIfNewOccasionSelectionIsEnabled() ? (
            occasions.length > 1 ? (
                getFormattedOccasionWithNewElement(occasions, null, onOccasionSelected, selectedOccasion)
            ) : (
                getFormattedOccasion(occasions[0], 0, onOccasionSelected, selectedOccasion)
            )
        ) : (
            occasions.map((occasion, idx, arr) => {
                return getFormattedOccasion(occasion, idx, onOccasionSelected, selectedOccasion);
            })
        )
    ) : (
        <div>
            {
                //t('menu.no_menu_data')
            }
        </div>
    );
};
/**
 * TABS HTML
 */
const getOccasionsTabsHtml = (
    selectedMenu,
    occasions,
    selectedOccasion,
    selectedDiet,
    selectedYear,
    t,
    onRecipeClick,
) => {
    return isValidMenu(selectedMenu) ? (
        occasions.map((occasion, idx) => {
            //const relatedDiets = selectedMenu.diets.filter((diet) => { return diet.occasion_id === occasion.occasion_id });
            return getFormattedOccasionTabs(
                occasion,
                idx,
                selectedMenu.recipes,
                selectedOccasion,
                selectedDiet,
                onRecipeClick,
            );
        })
    ) : (
        <div>
            {
                //t('menu.no_elements')
            }
        </div>
    );
};
/**
 * Data for DOWNLOAD BUTTON
 */
const getDownloadButtonData = (data = null, t, checkIsStartMonth = false) => {
    if (!data) data = moment();
    const momentData = moment(data, "YYYY-MM-DD");
    let date = momentData.clone();
    let year = momentData.format("YYYY");
    let month = momentData.format("MMMM");
    let isEnabled = true;

    if (checkIsStartMonth) {
        const initMonth = moment(data).month();
        const initYear = moment(data).year();
        const initMonthDate = moment({ year: initYear, month: initMonth, day: 1 });

        isEnabled = momentData.diff(initMonthDate, "days") < 5;
        if (isEnabled) {
            const previousMonthData = moment(data).subtract(1, "months");
            date = previousMonthData.clone();
            year = previousMonthData.format("YYYY");
            month = previousMonthData.format("MMMM");
        }
    }

    return {
        month,
        year,
        date,
        text: `${t(getDownloadKeyString())} ${t("download.months." + month.toLowerCase())} ${year}`,
        isEnabled,
    };
};
/**Filter years by  diets and occasion*/
const getFilteredYears = (diets, selectedDiet = DEFAULT_DIET) => {
    if (!diets || diets.length === 0) return [getYearByDietName(selectedDiet)];
    return diets && diets.length > 0
        ? diets
              .filter(diet => {
                  return selectedDiet === DEFAULT_DIET || selectedDiet.diet_id === diet.diet_id;
              })
              .map(itm => {
                  return getYearByDietName(itm);
              })
        : [];
};
/**Get SoMeal Dinner suggestions */
const getSuggestions = (suggestions, advices, selectedOccasion, selectedDiet, t) => {
    const currentLng = getCurrentLanguage();
    if (suggestions && Array.isArray(suggestions) && suggestions.length > 0) {
        const correctSuggestions = suggestions.find(suggestion => {
            return (
                suggestion.occasion_id === selectedOccasion.occasion_id && suggestion.diet_id === selectedDiet.diet_id
            );
        });

        return correctSuggestions?.recipes ? (
            correctSuggestions.recipes.map((recipe, idx, arr) => {
                const space = arr.length - 1 !== idx ? ", " : "";
                const name = getTranslatedString(recipe.name, currentLng).trim();

                return (
                    <span key={"suggestion-" + idx}>
                        {name}
                        {space}
                    </span>
                );
            })
        ) : (
            <div>{t("menu.no_recomendaciones_data")}</div>
        );
    }

    /*
    if (advices?.length > 0) {
        const fullText = advices.reduce((prevValue, currentAdvice, arr) => {
            const textToDisplay = currentAdvice?.abstract
                ? currentAdvice?.abstract
                : currentAdvice?.text
                ? currentAdvice?.text
                : "";
            return prevValue + textToDisplay;
        }, "");
        return checkIfStringIsHtml(fullText) ? (
            <div className="advices-container" dangerouslySetInnerHTML={{ __html: fullText }} />
        ) : (
            <div className="advices-container">{fullText}</div>
        );
    }
    */
    if (advices?.length > 0) {
        const currentAdvice = advices[0];
        let fullText = currentAdvice?.abstract
            ? currentAdvice?.abstract
            : currentAdvice?.text
              ? currentAdvice?.text
              : "";
        const containMedia = currentAdvice.img || false;
        const mediaButton = containMedia ? (
            <div
                className="box-area advice-dinner-media-container clickable"
                onClick={e => mediaButtonOnClick(e, currentAdvice?.img)}
            >
                {/*
                    <img src={mediaButtonSvg} />
                    */}
                <div className="text">{t("menu.advice_dinner_media_button_text")}</div>
            </div>
        ) : (
            ""
        );
        return checkIfStringIsHtml(fullText) ? (
            <div className="advices-container">
                <div className="advice-text" dangerouslySetInnerHTML={{ __html: fullText }} />
                {mediaButton}
            </div>
        ) : (
            <div className="advices-container">
                {fullText}
                {mediaButton}
            </div>
        );
    }

    return <div>{t("menu.no_recomendaciones_data")}</div>;
};
/**Check if asterisk comment is enabled*/
const checkIfAsteriskIsEnabled = () => {
    return getIfMenuAsteriskIsEnabled() && !checkIfChildrenModeIsActivaded();
};
class Menu extends Component {
    constructor(props) {
        super(props);

        if (!_.isEmpty(props.pageState[PAGES.HOME])) {
            /**Restoring state from Redux when turning back from RecipeDetail or Activity page */
            const parsedState = JSON.parse(props.pageState[PAGES.HOME]);
            this.state = Object.assign(
                {},
                {
                    ...parsedState,
                    restoredByState: true,
                },
            );

            props.removePageState({ page: PAGES.HOME });
        } else {
            const selectedDiet = this.props?.user?.selectedProfile?.diet
                ? convertDietProfileIntoDietMenu(this.props?.user?.selectedProfile.diet)
                : DEFAULT_DIET;

            this.state = {
                downloadingPdf: false,
                cid: props.user.canteen_id ? props.user.canteen_id : null,
                menu: {},
                occasions: [],
                diets: [],
                years: [],
                selectedMenu: [],
                selectedProfile: this.props?.user?.selectedProfile?.diet,
                selectedDate: moment().format(MENU_DATE_FORMAT),
                selectedOccasion: DEFAULT_OCCASION,
                selectedDiet: selectedDiet,
                selectedYear: selectedDiet ? getFilteredYears([selectedDiet], selectedDiet) : DEFAULT_YEAR,
                nutritionalData: getNutrinionalData([]),
                news: getNewsHome(this.props?.news?.news),
                newsChildren: getNewsChildren(this.props?.news?.news),
                activities: getActivities(this.props?.news?.news),
                newActivities: getActivitiesAreaA5(this.props?.news?.news),
                advices: getAdvices(this.props?.news?.news),
                restoredByState: false,
                rateRecipeModalVisible: false,
            };
        }

        this.onOccasionSelected = this.onOccasionSelected.bind(this);
        this.onDietSelected = this.onDietSelected.bind(this);
        this.onYearSelected = this.onYearSelected.bind(this);
        this.onDateSelected = this.onDateSelected.bind(this);
        this.loadMessages = this.loadMessages.bind(this);
        this.loadMenuPerDate = this.loadMenuPerDate.bind(this);
        this.getDataPerPDF = this.getDataPerPDF.bind(this);
        this.onRecipeClick = this.onRecipeClick.bind(this);
        this.onActivityClick = this.onActivityClick.bind(this);
        this.onNewActivityClick = this.onNewActivityClick.bind(this);
        this.goToSelectedActivity = this.goToSelectedActivity.bind(this);
        this.onActivityChildrenClick = this.onActivityChildrenClick.bind(this);
        this.goToAllergensPage = this.goToAllergensPage.bind(this);
        this.openModalRateRecipes = this.openModalRateRecipes.bind(this);
        this.closeModalRateRecipes = this.closeModalRateRecipes.bind(this);
        this.onRateRecipes = this.onRateRecipes.bind(this);
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.restoredByState) return prevState;

        let newState = { ...prevState };

        if (nextProps?.user?.selectedProfile?.cid !== prevState.cid) {
            newState = { cid: nextProps?.user?.selectedProfile?.cid };
        }

        /**Changed selected profile */
        if (nextProps?.user?.selectedProfile?.docId !== prevState?.selectedProfile?.docId) {
            const selectedDiet = nextProps?.user?.selectedProfile?.diet
                ? convertDietProfileIntoDietMenu(nextProps?.user?.selectedProfile?.diet)
                : null;

            const selectedYear = getYearByDietName(selectedDiet).toUpperCase();

            newState = {
                selectedProfile: nextProps?.user?.selectedProfile,
                cid: nextProps?.user?.selectedProfile.cid,
                selectedDiet,
                selectedYear,
            };
        }

        return newState;
    }
    shouldComponentUpdate(nextProps, nextState) {
        return !nextState.restoredByState;
    }
    async componentDidUpdate(prevProps, prevState) {
        if (
            prevState?.cid !== this.state?.cid ||
            prevState?.selectedProfile?.docId !== this.state?.selectedProfile?.docId
        ) {
            /**
             * Different canteens
             */
            await this.loadMenuPerDate(this.state.selectedDate);
            await this.loadMessages(this.state.selectedDate);
            changeCalendarDate(moment().format(MENU_DATE_FORMAT), this.state.selectedDate);
        }
    }
    async componentDidMount() {
        try {
            seo({
                title: SITE_TITLE + " || Home",
                metaDescription: "",
            });

            scrollTop();

            if (!this.state.restoredByState) {
                await this.context.api.getCanteenData(this.state.cid);
                await this.loadMenuPerDate(this.state.selectedDate, false, history?.action === "POP");
                await this.loadMessages(this.state.selectedDate);
                changeCalendarDate(moment().format(MENU_DATE_FORMAT), this.state.selectedDate);
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.props.deactivateLoader();
        }
    }
    async loadMenuPerDate(date, clickedSwiperDate = false, loadFromState = false) {
        this.props.activateLoader();

        const menuFromFirebase = !loadFromState
            ? await this.context.api.getMenu(this.state.cid, date)
            : this.props.menu[date];
        const menu = getFormattedMenu(menuFromFirebase);

        const {
            selectedMenu,
            selectedOccasion,
            occasions = [],
            diets,
            years,
            selectedDiet,
            selectedYear,
            nutritionalData,
        } = getFullMenuDataFormatted(
            this.state,
            this.props,
            menu,
            clickedSwiperDate,
            this.getNutrinionalDataByOccasion,
        );

        setOccasionToBody(selectedOccasion?.occasion_id);
        setDietToBody(selectedDiet?.diet_id);

        this.setState(
            {
                selectedDate: date,
                selectedMenu: selectedMenu,
                selectedOccasion: selectedOccasion,
                occasions: occasions,
                diets: diets,
                years: years,
                selectedDiet: selectedDiet,
                selectedYear: selectedYear,
                nutritionalData: nutritionalData,
                restoredByState: false,
            },
            () => {
                if (!loadFromState) {
                    this.props.setMenu({ date, menu: menuFromFirebase });
                }
                this.props.deactivateLoader();
            },
        );
    }
    async loadMessages(selectedDate = moment().format(MENU_DATE_FORMAT)) {
        this.props.activateLoader();
        const token = await this.context.api.getToken();
        const messagesData = await api.news.getAllNews(token, this.state.cid, selectedDate);
        if (messagesData?.success) {
            this.props.setFiltersAndMessages(messagesData.data);
            const news = getNewsHome(messagesData.data.news),
                activities = getActivities(messagesData.data.news),
                newActivities = getActivitiesAreaA5(messagesData.data.news),
                advices = getAdvices(messagesData.data.news),
                newsChildren = getNewsChildren(messagesData.data.news);
            this.setState(
                {
                    news: news && Array.isArray(news) ? news.sort(sortByPosAscending) : [],
                    activities: activities && Array.isArray(activities) ? activities.sort(sortByPosAscending) : [],
                    newActivities:
                        newActivities && Array.isArray(newActivities) ? newActivities.sort(sortByPosAscending) : [],
                    advices: advices && Array.isArray(advices) ? advices.sort(sortByPosAscending) : [],
                    newsChildren:
                        newsChildren && Array.isArray(newsChildren) ? newsChildren.sort(sortByPosAscending) : [],
                },
                () => {
                    this.props.deactivateLoader();
                },
            );
        } else {
            this.props.deactivateLoader();
        }
    }
    async onOccasionSelected(event = null, occasionSelected) {
        const self = this;
        if (!occasionSelected) return;

        setOccasionToBody(occasionSelected?.occasion_id);

        this.setState({
            selectedOccasion: occasionSelected,
            nutritionalData: self.getNutrinionalDataByOccasion(
                occasionSelected,
                this.state.selectedDiet, //diets[0],
                this.state.selectedYear, //years[0],
                this.state.selectedMenu.recipes,
            ),
            restoredByState: false,
        });

        return true;
    }
    async onDietSelected(event = null, dietSelected) {
        if (!dietSelected) return;

        const occasions = getValidAndWithRecipesOccasions(this.state.selectedMenu, dietSelected, this.state.diets);

        setDietToBody(dietSelected?.diet_id);

        this.setState({
            occasions,
            selectedOccasion: occasions?.length > 0 ? occasions[0] : DEFAULT_OCCASION,
            selectedDiet: dietSelected,
            nutritionalData: this.getNutrinionalDataByOccasion(
                this.state.selectedOccasion,
                dietSelected,
                this.state.selectedYear,
                this.state.selectedMenu.recipes,
            ),
            restoredByState: false,
        });
    }
    async onYearSelected(event = null, yearSelected) {
        if (!yearSelected) return;

        const diets = this.state?.selectedMenu?.dietsFormatted[yearSelected]
            ? this.state.selectedMenu.dietsFormatted[yearSelected]
            : this.state.diets;
        const selectedDiet =
            this.state?.selectedMenu?.dietsFormatted[yearSelected]?.length > 0
                ? checkDietExistenceInNewYear(
                      this.state.selectedMenu.dietsFormatted[yearSelected],
                      this.state.selectedDiet,
                  )
                : this.state.selectedDiet;

        const occasions = getValidAndWithRecipesOccasions(this.state.selectedMenu, selectedDiet, diets),
            selectedOccasion = occasions?.length > 0 ? occasions[0] : DEFAULT_OCCASION;

        setOccasionToBody(selectedOccasion?.occasion_id);
        setDietToBody(selectedDiet?.diet_id);

        this.setState({
            selectedYear: yearSelected,
            occasions,
            diets,
            selectedDiet,
            selectedOccasion,
            nutritionalData: this.getNutrinionalDataByOccasion(
                this.state.selectedOccasion,
                selectedDiet,
                yearSelected,
                this.state.selectedMenu.recipes,
            ),
            restoredByState: false,
        });
    }
    async onDateSelected(event, selectedDate) {
        event.preventDefault();
        const self = this;
        this.setState({ downloadingPdf: true, selectedDate, restoredByState: false }, async () => {
            try {
                await self.loadMenuPerDate(selectedDate, true);
                await self.loadMessages(selectedDate);
                changeCalendarDate(moment().format(MENU_DATE_FORMAT), selectedDate);
            } catch (e) {
                console.log(e);
            } finally {
                self.props.deactivateLoader();
                //Using this method to not permit update using componentWillReceiveProps
                setTimeout(() => {
                    self.setState({ downloadingPdf: false });
                }, 1000);
            }
        });
    }
    getNutrinionalDataByOccasion(selectedOccasion = null, selectedDiet = null, selectedYear = null, allRecipes = null) {
        selectedOccasion = selectedOccasion ? selectedOccasion : this.state.selectedOccasion;
        allRecipes = allRecipes ? allRecipes : this.state.selectedMenu.recipes;
        const correctedRecipes = getRecipesFromOccasionAndDietId(
            allRecipes,
            selectedOccasion.occasion_id,
            selectedDiet.diet_id,
        );
        return getNutrinionalData(correctedRecipes);
    }
    async getDataPerPDF(e, date) {
        const self = this;
        e.preventDefault();
        if (date) {
            this.setState({ downloadingPdf: true }, async () => {
                try {
                    const { selectedOccasion, selectedDiet, selectedMenu } = this.state;
                    const { occasions = [] } = selectedMenu;
                    const useDate = date;
                    this.props.activateLoader();
                    const token = await this.context.api.getToken();
                    const month = moment(useDate).format("MM");
                    const year = moment(useDate).format("YYYY");
                    const monthN = parseInt(month),
                        yearN = parseInt(year),
                        lang = getCurrentLanguage(),
                        dataToSend = getDataForDownloadPdf(
                            token,
                            this.props.user.canteen_id,
                            monthN,
                            yearN,
                            lang,
                            getIfDownloadPdfWithAllOccasionsIsEnabled(),
                            occasions,
                            selectedOccasion,
                            selectedDiet,
                        );

                    downloadMenuData(
                        dataToSend?.monthN,
                        dataToSend?.yearN,
                        dataToSend?.dataSelected?.diet,
                        dataToSend?.lang,
                        dataToSend?.canteen_id,
                    );

                    const pdfData = await api.canteen.getPDFBytesHandler(
                        dataToSend.token,
                        dataToSend.canteen_id,
                        dataToSend.monthN,
                        dataToSend.yearN,
                        dataToSend.lang,
                        dataToSend.dataSelected,
                        {},
                    );

                    if (pdfData?.success) {
                        downloadPdf(pdfData.data);
                    }
                } catch (e) {
                    console.log(e);
                } finally {
                    self.props.deactivateLoader();
                    //Using this method to not permit update using componentWillReceiveProps
                    setTimeout(() => {
                        self.setState({ downloadingPdf: false, restoredByState: false });
                    }, 1000);
                }
            });
        }
    }
    goToSelectedActivity(arrayData = [], activityClicked = null) {
        if (!activityClicked || _.isEmpty(activityClicked)) return;
        if (!arrayData || !Array.isArray(arrayData) || arrayData.length <= 0) return;

        const id = activityClicked.id;
        if (!id || id === "") return;

        const filteredActivity = arrayData.find(itm => {
            return itm.id === id;
        });

        if (filteredActivity) {
            const activityToPass = {
                id: filteredActivity.id,
                ...filteredActivity.message,
            };

            const dataToPass = {
                activity: activityToPass,
                fromHome: true,
            };

            this.props.setPageState({ page: PAGES.HOME, state: JSON.stringify(this.state) });
            history.pushWithCheck("/" + PAGES.ACTIVITY, dataToPass);
        }
    }
    onActivityClick(e, activityClicked) {
        this.goToSelectedActivity(this.props?.news?.news[1]?.news, activityClicked);
    }
    onNewActivityClick(e, activityClicked) {
        this.goToSelectedActivity(this.props?.news?.news[4]?.news, activityClicked);
    }
    onActivityChildrenClick(e, activityClicked) {
        if (!activityClicked || _.isEmpty(activityClicked)) return;

        const id = activityClicked.id;
        if (!id || id === "") return;

        const filteredActivity = this.props?.news?.news[2]?.news.filter(itm => {
            return itm.id === id;
        })[0];
        if (filteredActivity) {
            const activityToPass = {
                id: filteredActivity.id,
                ...filteredActivity.message,
            };

            const dataToPass = {
                activity: activityToPass,
                fromHome: true,
            };

            this.props.setPageState({ page: PAGES.HOME, state: JSON.stringify(this.state) });
            history.pushWithCheck("/" + PAGES.ACTIVITY, dataToPass);
        }
    }
    onRecipeClick(e, recipe) {
        if (getIfRecipeDetailEnabled() && recipe) {
            this.props.setPageState({ page: PAGES.HOME, state: JSON.stringify(this.state) });
            seeRecipeDetailData(recipe, this.state.selectedDate);
            history.push("/" + PAGES.RECIPE_DETAIL, {
                recipe,
                cid: this.state?.cid,
                selectedDiet: this.state?.selectedDiet,
                selectedOccasion: this.state?.selectedOccasion,
                selectedDate: this.state?.selectedDate,
            });
        }
    }
    goToAllergensPage(e) {
        e.preventDefault();
        history.pushWithCheck("/" + PAGES.ALLERGENS);
    }
    openModalRateRecipes(e) {
        if (e) {
            e.preventDefault();
        }

        const { selectedMenu = { recipes: [] } } = this.state,
            { recipes = [] } = selectedMenu;

        if (
            recipes?.length > 0 &&
            getRecipesFromOccasionAndDietIdForRates(
                recipes,
                this.state.selectedOccasion.occasion_id,
                this.state.selectedDiet.diet_id,
            )?.length > 0
        ) {
            this.setState({ rateRecipeModalVisible: true, restoredByState: false });
        } else {
            error(this.props.t("menu.no_recipe_to_rate"));
        }
    }
    closeModalRateRecipes(e) {
        if (e) {
            e.preventDefault();
        }

        this.setState({ rateRecipeModalVisible: false });
    }
    async onRateRecipes(e, recipesRating) {
        const out = Result.default();

        try {
            e.preventDefault();

            const recipesToPass = recipesRating.filter(recipeRate => {
                const { vote } = recipeRate;
                return vote > -1;
            });

            if (recipesToPass?.length > 0) {
                const { cid, selectedDate, selectedOccasion, selectedDiet } = this.state;

                //API - firebase function
                const dataReturned = await api.menu.rateRecipes(
                    await this.context?.api?.getToken(),
                    cid,
                    selectedDate,
                    recipesToPass,
                    selectedOccasion.occasion_id,
                    selectedDiet.diet_id,
                );

                Object.assign(out, dataReturned);

                /*
                    //Using Firebase.context
                    dataReturned = await this.context.api.onRateRecipes(
                        recipesToPass,
                        cid,
                        selectedDate,
                        selectedOccasion,
                        selectedDiet,
                    );
    
                Object.assign(out, dataReturned);
                */
            } else {
                console.log("No recipes has been rated");
            }
        } catch (error) {
            console.log("error in onRateRecipes");
            console.log(error);
        } finally {
            return out;
        }
    }
    render() {
        const { t } = this.props,
            { selectedDate, diets, selectedDiet, years, selectedYear, rateRecipeModalVisible } = this.state,
            isSoHappy = getIfSoHappy();

        return (
            <Fragment>
                <Header />
                <div className="middle-content justify-content-between">
                    <section className="section-one">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <div className="left-area">
                                        <div className="hide-on-mobile">
                                            {isSoHappy ? (
                                                <>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <InfanitalCicloSlider
                                                                t={t}
                                                                isDesktop={false}
                                                                useLittleVersion={true}
                                                                years={years}
                                                                selectedYear={selectedYear}
                                                                onYearSelected={this.onYearSelected}
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <MenuGeneralSlider
                                                                t={t}
                                                                isDesktop={false}
                                                                useLittleVersion={true}
                                                                diets={diets}
                                                                selectedDiet={selectedDiet}
                                                                onDietSelected={this.onDietSelected}
                                                            />
                                                        </div>
                                                    </div>
                                                    <DownloadButton
                                                        icon="icon-download-arrow"
                                                        {...getDownloadButtonData(selectedDate, this.props.t, true)}
                                                        onClick={this.getDataPerPDF}
                                                    />
                                                    <DownloadButton
                                                        icon="icon-download-arrow"
                                                        {...getDownloadButtonData(selectedDate, this.props.t, false)}
                                                        onClick={this.getDataPerPDF}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <InfanitalCicloSlider
                                                        t={t}
                                                        isDesktop={true}
                                                        years={years}
                                                        selectedYear={selectedYear}
                                                        onYearSelected={this.onYearSelected}
                                                    />

                                                    <MenuGeneralSlider
                                                        t={t}
                                                        isDesktop={true}
                                                        diets={diets}
                                                        selectedDiet={selectedDiet}
                                                        onDietSelected={this.onDietSelected}
                                                    />
                                                </>
                                            )}
                                            {isSoHappy && (
                                                <>
                                                    <div className="games-link-title">
                                                        {this.props.t("games.title")}
                                                    </div>
                                                    <GamesSlider
                                                        useTitle={true}
                                                        useSubTitle={true}
                                                        noItemsText={this.props.t("games.no_data")}
                                                        t={t}
                                                    />
                                                    <Margin marginTop="24px" />
                                                    {getIfNewsDirectLinkEnabled() && (
                                                        <div
                                                            className="activities-direct-link clickable"
                                                            onClick={() =>
                                                                history.pushWithCheck(INDEX_URL + PAGES.ACTIVITIES)
                                                            }
                                                        >
                                                            {this.props.t("children_mode.home_new_news_title")}
                                                            <br />
                                                            <span className="see-all-text">
                                                                {this.props.t("menu.see_all_activities")}
                                                            </span>
                                                        </div>
                                                    )}
                                                    <ActivitySlider
                                                        useTitle={!getIfNewsDirectLinkEnabled()}
                                                        useSubTitle={getIfNewsDirectLinkEnabled()}
                                                        activities={this.state.newActivities}
                                                        onClick={this.onNewActivityClick}
                                                        noItemsText={this.props.t("activities.no_data")}
                                                        t={t}
                                                    />
                                                </>
                                            )}
                                            {!isSoHappy && (
                                                <>
                                                    <DownloadButton
                                                        icon="icon-download-arrow"
                                                        {...getDownloadButtonData(selectedDate, this.props.t, true)}
                                                        onClick={this.getDataPerPDF}
                                                    />
                                                    <DownloadButton
                                                        icon="icon-download-arrow"
                                                        {...getDownloadButtonData(selectedDate, this.props.t, false)}
                                                        onClick={this.getDataPerPDF}
                                                    />
                                                </>
                                            )}
                                        </div>
                                        {/**
											<Advertisment image={advertisement01} text="Como Reducir El Desperdico De Alimentos?" />
										*/}
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <div className="menu-area">
                                        <div className="default-heading">
                                            <h1>
                                                {isValidMenu(this.state.selectedMenu) &&
                                                this.state.selectedDiet &&
                                                this.state.selectedDiet.diet_name &&
                                                this.state.selectedDiet.diet_name[getCurrentLanguage()]
                                                    ? getDietNameFormatted(this.state.selectedDiet)
                                                    : t("menu.no_menu_data")}
                                            </h1>
                                        </div>
                                        <DateSlider
                                            startingDate={this.state.selectedDate}
                                            onDateSelected={this.onDateSelected}
                                        />
                                        <div className="hide-on-desktop">
                                            <InfanitalCicloSlider
                                                t={t}
                                                isDesktop={false}
                                                years={this.state.years}
                                                selectedYear={this.state.selectedYear}
                                                onYearSelected={this.onYearSelected}
                                            />
                                            <MenuGeneralSlider
                                                t={t}
                                                isDesktop={false}
                                                diets={this.state.diets}
                                                selectedDiet={this.state.selectedDiet}
                                                onDietSelected={this.onDietSelected}
                                            />
                                        </div>
                                        {isSoHappy && (
                                            <div className="hide-on-desktop download-area">
                                                <Margin marginBottom="16px" />
                                                <DownloadButton
                                                    icon="icon-download-arrow"
                                                    {...getDownloadButtonData(selectedDate, this.props.t, true)}
                                                    onClick={this.getDataPerPDF}
                                                />
                                                <Margin marginTop="8px" />
                                                <DownloadButton
                                                    icon="icon-download-arrow"
                                                    {...getDownloadButtonData(selectedDate, this.props.t, false)}
                                                    onClick={this.getDataPerPDF}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {/** OCCASION TABS SELECTOR */}
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        {getOccasionsTabsSelectorHtml(
                                            this.state.selectedMenu,
                                            this.state.occasions,
                                            this.state.selectedOccasion,
                                            this.onOccasionSelected,
                                            t,
                                        )}
                                    </ul>
                                    {/** OCCASION TABS */}
                                    <div className="tab-content" id="myTabContent">
                                        {getOccasionsTabsHtml(
                                            this.state.selectedMenu,
                                            this.state.occasions,
                                            this.state.selectedOccasion,
                                            this.state.selectedDiet,
                                            this.state.selectedYear,
                                            t,
                                            this.onRecipeClick,
                                        )}

                                        {checkIfAsteriskIsEnabled() ? (
                                            <div className="menu-asterisk-description-container">
                                                {this.props.t("menu.asterisk_food_description").split("allergie")[0]}
                                                <span className="clickable" onClick={this.goToAllergensPage}>
                                                    {this.props.t("menu.allergens")}
                                                </span>
                                                {this.props.t("menu.asterisk_food_description").split("allergie")[1]}
                                            </div>
                                        ) : null}
                                    </div>
                                    {getIfNutritionalDataAreEnabled(this.props.canteen) && (
                                        <div className="information-area">
                                            <div className="default-heading">
                                                <h2>{t("menu.information_nutrinionals")}</h2>
                                            </div>
                                            {/** NUTRITIONAL DATA */}
                                            {getNutritionalDataHtml(this.state.nutritionalData, t)}
                                        </div>
                                    )}
                                    <div className="recomendaciones-area">
                                        <div
                                            className={
                                                getIfImageRecommendationsEnabled()
                                                    ? "suggestions__img-container"
                                                    : "suggestions__img-container disabled"
                                            }
                                        >
                                            <img className="suggestions__img" src={recommendationsSvg} />
                                        </div>
                                        <div className="default-heading">
                                            <h2>{this.props.t("menu.dinner_recommendations_title")}</h2>
                                        </div>
                                        <div className="suggestions-container">
                                            {getSuggestions(
                                                this.state.selectedMenu.recommendations,
                                                this.state.advices,
                                                this.state.selectedOccasion,
                                                this.state.selectedDiet,
                                                t,
                                            )}
                                        </div>
                                        <Margin marginTop={"16px"} />
                                        <Spacer />
                                        {/**
									<figure className="schermata">
										<img src={schermata} alt="schermata"/>
									</figure>
								 */}
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <div className="right-area">
                                        <NewsSlider news={this.state.news} t={t} />
                                        {getIfNewsDirectLinkEnabled() ? (
                                            <div
                                                className="news-direct-link clickable"
                                                onClick={() => history.pushWithCheck(INDEX_URL + PAGES.NEWS)}
                                            >
                                                {this.props.t("children_mode.home_news_title")}
                                                <br />
                                                <span className="see-all-text">
                                                    {this.props.t("menu.see_all_news")}
                                                </span>
                                            </div>
                                        ) : null}
                                        <ActivitySlider
                                            useTitle={!getIfNewsDirectLinkEnabled()}
                                            useSubTitle={getIfNewsDirectLinkEnabled()}
                                            activities={this.state.activities}
                                            onClick={this.onActivityClick}
                                            t={t}
                                        />
                                        <div className="hide-on-desktop">
                                            {isSoHappy && (
                                                <>
                                                    <div className="games-link-title">
                                                        {this.props.t("games.title")}
                                                    </div>
                                                    <GamesSlider
                                                        useTitle={true}
                                                        useSubTitle={true}
                                                        noItemsText={this.props.t("games.no_data")}
                                                        t={t}
                                                    />
                                                    <Margin marginTop="24px" />
                                                    {getIfNewsDirectLinkEnabled() && (
                                                        <div
                                                            className="activities-direct-link clickable"
                                                            onClick={() =>
                                                                history.pushWithCheck(INDEX_URL + PAGES.ACTIVITIES)
                                                            }
                                                        >
                                                            {this.props.t("children_mode.home_new_news_title")}
                                                            <br />
                                                            <span className="see-all-text">
                                                                {this.props.t("menu.see_all_activities")}
                                                            </span>
                                                        </div>
                                                    )}
                                                    <ActivitySlider
                                                        useTitle={!getIfNewsDirectLinkEnabled()}
                                                        useSubTitle={getIfNewsDirectLinkEnabled()}
                                                        activities={this.state.newActivities}
                                                        onClick={this.onNewActivityClick}
                                                        noItemsText={this.props.t("activities.no_data")}
                                                        t={t}
                                                    />
                                                </>
                                            )}
                                        </div>
                                        <div
                                            className="box-area suggestion-message clickable"
                                            onClick={() => {
                                                history.pushWithCheck("/" + PAGES.MAIL_BOX);
                                            }}
                                        >
                                            <img src={bustaSvg} alt="bùson" className="busta-svg-image" />
                                            <div className="default-heading">
                                                <h2>{this.props.t("menu.link_send_message")}</h2>
                                            </div>
                                            <p>{this.props.t("menu.send_message")}</p>
                                        </div>

                                        {!isSoHappy && (
                                            <div className="hide-on-desktop">
                                                <Spacer marginBottom="16px" />
                                                <DownloadButton
                                                    icon="icon-download-arrow"
                                                    {...getDownloadButtonData(selectedDate, this.props.t, true)}
                                                    onClick={this.getDataPerPDF}
                                                />
                                                <Margin marginTop="8px" />
                                                <DownloadButton
                                                    icon="icon-download-arrow"
                                                    {...getDownloadButtonData(selectedDate, this.props.t, false)}
                                                    onClick={this.getDataPerPDF}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="right-area-children col-12">
                                    <div className="col-10 col-sm-10 col-md-3 right-area-children__title games-link-title">
                                        {this.props.t("games.title")}
                                    </div>
                                    <GamesSlider
                                        useTitle={true}
                                        useSubTitle={true}
                                        noItemsText={this.props.t("games.no_data")}
                                        t={t}
                                    />
                                    <Margin marginTop="24px" />
                                    <div
                                        className="col-10 col-sm-10 col-md-3 right-area-children__title clickable"
                                        onClick={() => history.pushWithCheck(INDEX_URL + PAGES.NEWS)}
                                    >
                                        {this.props.t("children_mode.home_news_title")}
                                        <br />
                                        <span className="see-all-children">{this.props.t("menu.see_all_news")}</span>
                                    </div>
                                    <ActivitySlider
                                        useTitle={!getIfNewsDirectLinkEnabled()}
                                        useSubTitle={getIfNewsDirectLinkEnabled()}
                                        activities={this.state.newsChildren}
                                        onClick={this.onActivityChildrenClick}
                                        t={t}
                                    />
                                    <div
                                        className="col-10 col-sm-10 col-md-3 right-area-children__title activities-direct-link clickable"
                                        onClick={() => history.pushWithCheck(INDEX_URL + PAGES.ACTIVITIES)}
                                    >
                                        {this.props.t("children_mode.home_new_news_title")}
                                        <br />
                                        <span className="see-all-text">{this.props.t("menu.see_all_activities")}</span>
                                    </div>
                                    <ActivitySlider
                                        useTitle={!getIfNewsDirectLinkEnabled()}
                                        useSubTitle={getIfNewsDirectLinkEnabled()}
                                        activities={this.state.newActivities}
                                        onClick={this.onNewActivityClick}
                                        noItemsText={this.props.t("activities.no_data")}
                                        t={t}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
                {checkIfRateRecipeIsEnabled() && getIfUserHasEnabledToRate() && (
                    <>
                        <RateRecipeButton onClick={this.openModalRateRecipes} />
                        <RateRecipesModal
                            visible={rateRecipeModalVisible}
                            close={this.closeModalRateRecipes}
                            useFinalPageStep={false}
                            onSuccess={() => {
                                success(t("rate_recipe.modal.thanks"));
                            }}
                            onError={() => {
                                error(`${t("rate_recipe.modal.error")}!`);
                            }}
                            recipes={getRecipesFromOccasionAndDietIdForRates(
                                this.state.selectedMenu.recipes,
                                this.state.selectedOccasion.occasion_id,
                                this.state.selectedDiet.diet_id,
                            )}
                            onClick={this.onRateRecipes}
                        />
                    </>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    // ... computed data from state and optionally ownProps
    return {
        ...state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        activateLoader: () => dispatch({ type: LOADER_VISIBLE }),
        deactivateLoader: () => dispatch({ type: LOADER_INVISIBLE }),
        setFiltersAndMessages: payload => dispatch({ type: SET_NEWS, payload }),
        setMenu: payload => dispatch({ type: MENU_RECEIVED, payload }),
        setPageState: payload => dispatch({ type: SET_PAGE_STATE, payload }),
        removePageState: payload => dispatch({ type: DELETE_PAGE_STATE, payload }),
    };
};

Menu.contextType = FirebaseContext;

// `connect` returns a new function that accepts the component to wrap:
//const connectToStore = connect(mapStateToProps, mapDispatchToProps)
// and that function returns the connected, wrapper component:
//const ConnectedComponent = connectToStore(Menu2)

// We normally do both in one step, like this:
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Menu));
