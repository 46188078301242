/**Packages */
import { isEmpty } from "lodash";
import { createRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

/**Utils */
import { SITE_TITLE, INDEX_URL, PAGES } from "../../helpers/constants";
import { checkIfProfileEquals, sortByPosAscending, getEnabledLanguages } from "../../helpers/utils";
import { seo } from "../../helpers/seo";
import { error, success } from "../../helpers/toast";
import { validateGenericString, validatePassword } from "../../helpers/validators";
import { getIfModifyNameIsEnabled, getIfAddAccountEnabledInAccountPage } from "../../helpers/enviroment";
import { returnToParentMode } from "../../helpers/view";
import history from "../../helpers/history";

/**Components */
import OnChange from "../../components/OnChange";
import Input from "../../components/Input";
import LanguageSelector from "../../components/LanguageSelector";
import Profile from "../../components/Profile";
import Spacer from "../../components/Spacer";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../redux/actions/loaderActions";
import { USER_LOGGED_OUT, USER_DELETE_PROFILE, USER_SELECTED_PROFILE } from "../../redux/actions/userActions";
import { DELETE_CANTEEN_DATA } from "../../redux/actions/canteenActions";

/**FirebaseContext */
import { FirebaseContext } from "./../../firebase/firebase";

/**API */
import { api as httpAPI } from "../../api";

/**Images */
import pencilSvg from "../../images/pencil.svg";

/**SCSS */
import "./_account.scss";

class Account extends OnChange {
    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            name: props?.user?.name,
            surname: props?.user?.surname,
            email: props?.user?.email,
            password: "",
            confirmPassword: "",
            isDisabledForm: true,
            isDisabledAccount: true,
            deleteUserModalVisible: false,
        };

        this.accountInfo = this.accountInfo.bind(this);
        this.toggleUserProfilesForm = this.toggleUserProfilesForm.bind(this);
        this.toggleUserForm = this.toggleUserForm.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.changePasswordContainer = this.changePasswordContainer.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.languageInfo = this.languageInfo.bind(this);
        this.profileInfo = this.profileInfo.bind(this);
        this.accountForm = this.accountForm.bind(this);
        this.changePasswordForm = this.changePasswordForm.bind(this);
        this.onProfileSelection = this.onProfileSelection.bind(this);
        this.onDeleteProfile = this.onDeleteProfile.bind(this);
        this.getProfilesHtml = this.getProfilesHtml.bind(this);
        this.checkIfUserIsAnonymous = this.checkIfUserIsAnonymous.bind(this);
        this.goToEditProfilePage = this.goToEditProfilePage.bind(this);
        this.toggleDeleteUserModal = this.toggleDeleteUserModal.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);
        this.logout = this.logout.bind(this);

        this.inputNameRef = createRef();
        this.inputSurnameRef = createRef();
        this.profilesDiv = createRef();
    }

    componentDidMount() {
        seo({
            title: SITE_TITLE + " || Account",
            metaDescription: "",
        });

        this.props.activateLoader();
        if (this.props?.location?.state?.focusOnProfiles) {
            //this.profilesDiv?.current?.scrollIntoView();
            window.scrollTo({
                top: this.profilesDiv?.current?.offsetTop,
            });
        }
        this.props.deactivateLoader();
    }

    componentDidUpdate() {}

    checkIfUserIsAnonymous() {
        return this.props.user.anonymous;
    }

    validateForm() {
        const { name, surname } = this.state;
        if (!validateGenericString(name, 1)) {
            this.inputNameRef?.current?.focus();
            return false;
        }

        if (!validateGenericString(surname, 1)) {
            this.inputSurnameRef?.current?.focus();
            return false;
        }

        return true;
    }

    async toggleUserForm() {
        const newState = this.state;
        if (!newState.isDisabledForm && this.validateForm()) {
            const result = await this.context?.api?.updateNameSurname(this.state.name, this.state.surname);
            if (!result) {
                error(this.props.t("account.error.profile.update"));
            } else {
                success(this.props.t("account.success.update"));
            }
        } else if (!this.validateForm()) {
            error(this.props.t("error_parameters"));
        }

        newState.isDisabledForm = !newState.isDisabledForm;
        this.setState(newState);
    }

    toggleUserProfilesForm() {
        const newState = this.state;
        newState.isDisabledAccount = !newState.isDisabledAccount;
        this.setState(newState);
    }

    async onProfileSelection(e, profileSelected) {
        if (!profileSelected) return;

        await this.context?.api?.getCanteenData(profileSelected?.cid);
        await this.props.setSelectedProfile(profileSelected, this.context);

        history.pushWithCheck(INDEX_URL + PAGES.HOME);
    }

    async onDeleteProfile(e, profile) {
        e.preventDefault();

        if (!profile || isEmpty(profile)) return;

        this.props.activateLoader();

        try {
            const token = await this.context?.api?.getToken();

            if (!token || token === "") {
                //Case problem with token id
                error(this.props.t("account.error.profile.token_error"));
                return;
            }

            if (this.props.user.profiles.length <= 1) {
                //Case at least 1 profile
                error(this.props.t("account.error.profile.at_least_one_profile"));
                return;
            }

            const docId = profile.docId;
            const wasSelectedProfile = docId === this.props.user.selectedProfile.docId;
            const result = await httpAPI.profile.deleteProfile(token, docId);

            if (result && result.success) {
                this.props.deleteProfile({ docId });
                if (wasSelectedProfile) {
                    await this.props.setSelectedProfile(this.props.user.profiles[0], this.context);
                }
                success(this.props.t("account.success.delete_profile"));
            } else {
                error(this.props.t("account.error.profile.delete_service_error"));
            }
        } catch (e) {
            console.log("Error in onDeleteProfile method");
            console.log(e);
        } finally {
            this.toggleUserProfilesForm();
            this.props.deactivateLoader();
        }
    }

    logout(e) {
        try {
            this.props.activateLoader();
            this.context.logout();
            this.props.logout();
            returnToParentMode();
            history.pushWithCheck(INDEX_URL + PAGES.LOGIN);
        } catch (err) {
            console.error("Account - error in logout", err);
        } finally {
            this.props.deactivateLoader();
        }
    }

    toggleDeleteUserModal(e, value) {
        e.preventDefault();

        this.setState({
            deleteUserModalVisible: value,
        });
    }

    async deleteAccount() {
        this.props.activateLoader();
        try {
            const token = await this.context.api.getToken();
            await httpAPI.profile.deleteUser(token);
            this.context.logout();
            this.props.logout();
            history.pushWithCheck(INDEX_URL + PAGES.LOGIN);
        } catch (e) {
            console.log("e");
            console.log(e);
        } finally {
            this.setState({
                deleteUserModalVisible: false,
            });
            this.props.deactivateLoader();
        }
    }

    async changePassword(e) {
        e.preventDefault();
        try {
            this.props.activateLoader();
            const { password, confirmPassword } = this.state;
            if (validatePassword(password) && validatePassword(confirmPassword) && password === confirmPassword) {
                const result = await this.context.api.changeUserPassword(password);
                if (result) {
                    success(this.props.t("account.success.change_password"));
                    history.pushWithCheck(INDEX_URL + PAGES.HOME);
                } else {
                    error(this.props.t("account.error.change_password.service_error"));
                }
            } else {
                error(this.props.t("error_parameters"));
            }
        } catch (e) {
            console.log("e");
            console.log(e);
        } finally {
            this.props.deactivateLoader();
        }
    }

    goToEditProfilePage(e, profile) {
        if (!profile || isEmpty(profile)) return;

        const data = {
            id: profile.id,
            profile,
        };

        history.pushWithCheck(INDEX_URL + PAGES.EDIT_PROFILE, data);
    }

    accountForm() {
        return (
            <>
                <form>
                    {/*Nome*/}
                    <Input
                        innerRef={this.inputNameRef}
                        type="text"
                        className="form-control"
                        id="account_input_name"
                        value={this.state.name}
                        placeholder={this.props.t("account.name")}
                        disabled={this.state.isDisabledForm}
                        //onChange={e => this.onChange(e, "name", true, "generic")}
                        onChange={e => this.onChange(e, "name")}
                    />
                    {/*Mail*/}
                    <Input
                        innerRef={this.inputSurnameRef}
                        type="text"
                        className="form-control"
                        id="account_input_surname"
                        value={this.state.surname}
                        placeholder={this.props.t("account.surname")}
                        disabled={this.state.isDisabledForm}
                        //onChange={e => this.onChange(e, "surname", true, "generic")}
                        onChange={e => this.onChange(e, "surname")}
                    />
                    {/*Mail*/}
                    <Input
                        type="email"
                        className="form-control"
                        id="login_input_email"
                        value={this.state.user.email}
                        placeholder={this.props.t("account.email")}
                        disabled={true}
                        onChange={e => null}
                    />
                    <div className="form-group last">
                        <div className="btns-area">
                            <div className="col-12 text-right justify-content-right" onClick={this.toggleUserForm}>
                                {this.state.isDisabledForm ? (
                                    <img className="pencil-img" src={pencilSvg} alt="pencil" />
                                ) : (
                                    <i className="fa fa-check"></i>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            </>
        );
    }

    changePasswordForm() {
        return (
            <>
                <form>
                    {/*Password*/}
                    <Input
                        type="password"
                        className="form-control"
                        id="account_input_password"
                        placeholder={this.props.t("account.password")}
                        value={this.state.password}
                        autocomplete="new-password"
                        onChange={e => this.onChange(e, "password")}
                    />
                    {/*Conferma Password*/}
                    <Input
                        type="password"
                        className="form-control"
                        id="account_input_confirm_password"
                        placeholder={this.props.t("account.confirm_password")}
                        value={this.state.confirmPassword}
                        autocomplete="new-password"
                        onChange={e => this.onChange(e, "confirmPassword")}
                    />
                    <div className="form-group">
                        <div className="btns-area">
                            <button type="button" className="btn btn-primary" onClick={e => this.changePassword(e)}>
                                {this.props.t("change_password.confirm")}
                            </button>
                        </div>
                    </div>
                </form>
            </>
        );
    }

    accountInfo() {
        return (
            <div className="head-area row w-100">
                <div className="row align-items-center justify-content-center">
                    <div className="col-12">
                        <div className="default-heading uppercase">
                            <h3>{this.props.t("account.title")}</h3>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center justify-content-center">
                    <div className="col-12">{this.accountForm()}</div>
                </div>
                <Spacer />
            </div>
        );
    }

    changePasswordContainer() {
        return (
            <div className="head-area row w-100">
                <div className="row align-items-center justify-content-center">
                    <div className="col-12">
                        <div className="default-heading">
                            <h3>{this.props.t("account.change_password_title")}</h3>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center justify-content-center">
                    <div className="col-12">{this.changePasswordForm()}</div>
                </div>
                <Spacer />
            </div>
        );
    }

    languageInfo() {
        return (
            <div className="head-area row w-100" ref={this.profilesDiv}>
                <div className="row align-items-center justify-content-center">
                    <div className="col-12">
                        <div className="default-heading">
                            <h3>{this.props.t("account.change_language_title")}</h3>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center justify-content-center">
                    <div className="col-12">
                        <LanguageSelector languages={getEnabledLanguages(this.props?.canteen?.languages)} />
                    </div>
                </div>
                <Spacer />
            </div>
        );
    }

    getProfilesHtml(selectedProfile, profiles) {
        const profilesHtml = [
            <Profile
                key="account-profile-0"
                //key={"selected-profile"}
                profile={selectedProfile}
                onClick={e => this.onProfileSelection(e, selectedProfile)}
                isCurrentSelected={true}
                onDeleteClick={this.onDeleteProfile}
                canModifyName={getIfModifyNameIsEnabled()}
                goToEditProfilePage={getIfModifyNameIsEnabled() ? this.goToEditProfilePage : null}
                seePen={true}
            />,
        ];
        let i = 0;

        if (profiles) {
            profilesHtml.push(
                ...profiles.sort(sortByPosAscending).map(profile => {
                    if (!checkIfProfileEquals(selectedProfile, profile)) {
                        i++;
                        return (
                            <Profile
                                key={"account-profile-" + i}
                                profile={profile}
                                onClick={e => this.onProfileSelection(e, profile)}
                                isCurrentSelected={false}
                                onDeleteClick={this.onDeleteProfile}
                                canModifyName={getIfModifyNameIsEnabled()}
                                goToEditProfilePage={getIfModifyNameIsEnabled() ? this.goToEditProfilePage : null}
                                seePen={true}
                            />
                        );
                    } else {
                        return null;
                    }
                }),
            );
        }

        return profilesHtml;
    }

    profileInfo() {
        return (
            <div className="head-area row w-100">
                <div className="row align-items-center justify-content-center">
                    <div className="col-12">
                        <div className="default-heading">
                            <h3>{this.props.t("account.profile_title")}</h3>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center justify-content-center">
                    <div className="col-12 justify-content-center">
                        {this.props?.user?.profiles?.length > 0 ? (
                            this.getProfilesHtml(this.props.user.selectedProfile, this.props.user.profiles)
                        ) : (
                            /*
                            this.props?.user?.profiles?.sort(sortByPosAscending).map((profile, idx) => {
                                return (
                                    <Profile
                                        key={"account-profile-" + idx}
                                        profile={profile}
                                        onClick={e => this.onProfileSelection(e, profile)}
                                        isCurrentSelected={checkIfProfileEquals(
                                            this.props.user.selectedProfile,
                                            profile,
                                        )}
                                        onDeleteClick={this.onDeleteProfile}
                                        canModifyName={getIfModifyNameIsEnabled()}
                                        goToEditProfilePage={this.goToEditProfilePage}
                                        seePen={true}
                                    />
                                );
                            })
                            */
                            <div>{this.props.t("account.no_profile")}</div>
                        )}
                    </div>
                    {getIfAddAccountEnabledInAccountPage() ? (
                        <div id="add-account-id-container" className="col-12 text-center justify-content-center">
                            <Link to={PAGES.CREATE_PROFILE}>
                                <div className="box-area add-profile">
                                    <div className="add-icon">
                                        <span className="icon-plus" />
                                    </div>
                                    <div className="default-heading">
                                        <h2>{this.props.t("account.add_profile")}</h2>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ) : null}
                </div>
                <Spacer />
                <div className="row align-items-center justify-content-center">
                    <div className="btns-area">
                        <button
                            className="btn btn-primary btn-delete"
                            onClick={e => this.toggleDeleteUserModal(e, true)}
                        >
                            {this.props.t("account.delete_account")}
                        </button>
                    </div>
                </div>
                <Spacer />
            </div>
        );
    }

    render() {
        return (
            <>
                <Header />
                <div className="col-12 col-md-8 offset-md-2 d-flex flex-column justify-content-center w-100 text-center middle-content">
                    {!this.checkIfUserIsAnonymous() && this.accountInfo()}
                    {!this.checkIfUserIsAnonymous() && this.changePasswordContainer()}
                    {this.languageInfo()}
                    {this.profileInfo()}
                    <div
                        className="signout-area"
                        style={{ backgroundColor: "rgba(255, 255, 255, 0.8)", color: "#263a7b" }}
                    >
                        <Link
                            to="#"
                            onClick={this.logout}
                            style={{ backgroundColor: "rgba(255, 255, 255, 0.8)", color: "#263a7b" }}
                        >
                            <span className="icon-close-circle"></span>
                            {this.props.t("logout")}
                        </Link>
                    </div>
                </div>
                <Footer />
                <Modal
                    id="delete-account-modal"
                    close={e => this.toggleDeleteUserModal(e, false)}
                    visible={this.state.deleteUserModalVisible}
                >
                    <div className="delete-account-modal-container">
                        <h3 className="title">{this.props.t("account.modal_delete_account.title")}</h3>
                        <span className="description">{this.props.t("account.modal_delete_account.description")}</span>
                        <div className="buttons-container">
                            <button className="btn btn-primary" onClick={e => this.toggleDeleteUserModal(e, false)}>
                                {this.props.t("account.modal_delete_account.undo")}
                            </button>
                            <button className="btn btn-primary" onClick={this.deleteAccount}>
                                {this.props.t("account.modal_delete_account.confirm")}
                            </button>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    // ... computed data from state and optionally ownProps
    return {
        ...state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        activateLoader: () => dispatch({ type: LOADER_VISIBLE }),
        deactivateLoader: () => dispatch({ type: LOADER_INVISIBLE }),
        setSelectedProfile: async (payload, context) => {
            dispatch({ type: USER_SELECTED_PROFILE, payload });
            await context?.api?.setSelectedProfile(payload ? payload.docId : null);
        },
        deleteProfile: payload => dispatch({ type: USER_DELETE_PROFILE, payload }),
        logout: () => {
            dispatch({ type: USER_LOGGED_OUT });
            dispatch({ type: DELETE_CANTEEN_DATA });
        },
    };
};

Account.contextType = FirebaseContext;

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Account));
